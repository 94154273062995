import React from "react"
import { Link } from "gatsby"

import MobileMenu from "./mobileMenu";

const Header = () => (
  <div className="max-w-screen-xl mx-auto px-4 sm:px-6 mb-3 mt-3">
    <nav className="relative flex items-center justify-between sm:h-10 md:justify-center">
      <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
        <div className="flex items-center justify-between w-full md:w-auto">
          <Link to={"/"} aria-label="Home" className="text-orange-400 text-2xl">
            <span style={{fontFamily: "'Kanit', 'sans-serif'"}}>Lantern Ops</span>
          </Link>
          {/*<div className="-mr-2 flex items-center md:hidden">*/}
          {/*  <button type="button"*/}
          {/*          className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"*/}
          {/*          id="main-menu" aria-label="Main menu" aria-haspopup="true">*/}
          {/*    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">*/}
          {/*      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"*/}
          {/*            d="M4 6h16M4 12h16M4 18h16"/>*/}
          {/*    </svg>*/}
          {/*  </button>*/}
          {/*</div>*/}
        </div>
      </div>
      <div className="md:flex md:space-x-10">
        <Link to={"/"}
           className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out mr-2">Home</Link>
        <Link to={"/services"}
           className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out mr-2">Services</Link>
        <Link to={"/contact"}
           className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">Contact</Link>
      </div>
      <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
          <span className="inline-flex rounded-md shadow">
            <Link to={"/contact"}
               className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-blue-600 bg-white hover:text-blue-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-blue-700 transition duration-150 ease-in-out">
              Request Consultation
            </Link>
          </span>
      </div>
    </nav>
    <MobileMenu />
  </div>
)

export default Header
