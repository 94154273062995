import React from 'react';
import { useState } from 'react';

import Transition from "./transition/transition";

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <React.Fragment>
      <Transition
        show={isOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg">
          <div className="rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical"
               aria-labelledby="options-menu">
            <div className="py-1">
              <a href="#"
                 className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                 role="menuitem">View profile</a>
              <a href="#"
                 className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                 role="menuitem">Settings</a>
            </div>
            <div className="border-t border-gray-100"></div>
            <div className="py-1">
              <a href="#"
                 className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                 role="menuitem">Logout</a>
            </div>
          </div>
        </div>
      </Transition>
    </React.Fragment>
  )
}

export default MobileMenu
